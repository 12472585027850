import Arweave from "arweave";
import { wallet } from "./arweave_wallet";

const arweave = Arweave.init({
    host: "arweave.net",
    port: 443,
    protocol: "https",
    timeout: 20000,
    logging: false,
});

/**
 * Upload a file to Arweave
*/
export const uploadFile = async (fileType: string, file: File) => {
    let transaction;

    console.log(`fileType: ${fileType}, file: ${file}`)

    try {
        const buffer = await file.arrayBuffer();

        transaction = await arweave.createTransaction({ data: buffer });
        transaction.addTag("Content-Type", fileType);
        await arweave.transactions.sign(transaction, wallet);
    } catch (err) {
        console.log("create & sign transaction error:", err);
        throw new Error("Failed to create & sign transaction!");
    }

    let imageUrl: string = "";
    try {
        const response = await arweave.transactions.post(transaction);
        console.log(response);

        if(response.status === 200 && response.statusText === "OK") {
            const id = transaction.id;
            imageUrl = id ? `https://arweave.net/${id}` : "";
            return imageUrl;
        } else {
            console.error("Failed to upload file to Arweave!");
            throw new Error("Failed to upload file to Arweave!");
        }
        
        // console.log("imageUrl", imageUrl);
    } catch (err) {
        console.error("uploadLogo error: ", err);
        throw "Failed to upload file to Arweave!";
    }
}

/**
 * Upload metadata to Arweave
 */
export const uploadMetadata = async (_metadata: any): Promise<string> => {
    console.log("Uploading metadata...");
    const metadata = {
        name: _metadata.name,
        symbol: _metadata.symbol,
        description: _metadata.description,
        image: _metadata.image, // Image URL
        website: _metadata.website,
        twitter: _metadata.twitter,
        telegram: _metadata.telegram,
    };

    try {
        // Convert metadata to JSON and create a transaction
        const metadataRequest = JSON.stringify(metadata);
        const metadataTransaction = await arweave.createTransaction({
            data: metadataRequest,
        });
        metadataTransaction.addTag("Content-Type", "application/json");

        // Sign and post the transaction
        await arweave.transactions.sign(metadataTransaction, wallet);
        const response = await arweave.transactions.post(metadataTransaction);

        if (response.status === 200) {
            const metadataUri = `https://arweave.net/${metadataTransaction.id}`;
            console.log(`Metadata successfully uploaded: ${metadataUri}`);
            return metadataUri;
        } else {
            throw new Error("Failed to upload metadata to Arweave!");
        }
    } catch (error) {
        console.error("Error during metadata upload:", error);
        throw new Error("Failed to upload metadata!");
    }
};